import Vue from 'vue';
import App from './App.vue';
import router from './router/router.js';
import store from './store/store.js';
import http from './plugins/http.js';
import { i18n } from './i18n';
import vuetify from './plugins/vuetify';
import './sass/vuetiftyColor.scss';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
//import * as Sentry from '@sentry/vue';

Vue.config.productionTip = false;
Vue.prototype.$http = http;
Vue.use(i18n);
Vue.use(vuetify, {
   breakpoint: {
      thresholds: {
         ada: 800,
      },
   },
});

/* Sentry.init({
   Vue,
   dsn: 'https://1da1123930796c1135f4c08ff11d0fa8@o4505962531520512.ingest.sentry.io/4505962535321600',
   environment: process.env.VUE_APP_ENV,
   integrations: [
      new Sentry.BrowserTracing({
         // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
         tracePropagationTargets: ['localhost', /^https:\/\/api.sleep-well\.coach/],
         routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
   ],
   // Performance Monitoring
   tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
   // Session Replay
   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
}); */

Vue.directive('visibility', {
   bind(el, binding) {
      const {
         value: { id, callback },
      } = binding;

      const observer = new IntersectionObserver(entries => {
         const entry = entries[0];
         if (entry.isIntersecting) {
            callback(id, true);
         } else {
            callback(id, false);
         }
      });

      observer.observe(el);

      el._intersectionObserver = observer;
   },
   unbind(el) {
      if (el._intersectionObserver) {
         el._intersectionObserver.disconnect();
         delete el._intersectionObserver;
      }
   },
});

//! this is a crutch for the phone number input. Used in Account.vue
Vue.directive('phoneNumber', {
   bind: function (el) {
      el.addEventListener('input', function (event) {
         let value = event.target.value;
         value = value.replace(/\D/g, '');
         value = '+' + value;
         event.target.value = value;
      });
   },
});
//!end crutch

new Vue({
   router,
   store,
   vuetify,
   i18n,
   render: h => h(App),
}).$mount('#app');

// window.addEventListener('load', async () => {
//    if ('serviceWorker' in navigator) {
//       try {
//          const result = await navigator.serviceWorker.register('/sw.js');
//          console.log(result);
//       } catch (e) {
//          console.log('Service worker register fail');
//          console.log(e);
//       }
//    }
// });
