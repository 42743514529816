import Vue from 'vue';
import VueRouter from 'vue-router';

import routeApp from './routes/routeApp';

import middlewarePipeline from './middleware/middlewarePipeline';

Vue.use(VueRouter);

Vue.config.productionTip = false;

const router = routeApp;

router.beforeEach((to, from, next) => {
/*    if (
      from.name === 'login' ||
      from.name === 'advice' ||
      from.name === 'habits' ||
      from.name === 'menu' ||
      from.name === 'account' ||
      from.name === 'pricing'
   ) {
      window.history.forward(1);
   }
 */
   // if (from.name === 'app' && to.name !== 'app-profile' && to.name !== 'app-main-category' && to.name !== 'app-main-subcategory') {
   //    window.history.forward(1)
   // }

   if (to.meta.middleware) {
      const middleware = to.meta.middleware;
      const context = {
         to,
         from,
         next,
      };
      return middleware[0]({
         ...context,
         next: middlewarePipeline(context, middleware, 1),
      });
   } else {
      return next();
   }
});
export default router;
